<template>
  <v-toolbar
    dark
    flat
    style="position: fixed; width: 100%; z-index:99"
  >
<!--    :color="'rgba(128,128,128, 0.5)'">
    -->
    <v-toolbar-title>
      <v-app-bar-nav-icon @click="toggleNavigationBar"></v-app-bar-nav-icon>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <vs-select
        autocomplete
        class="selectExample"
        v-model="code"
        style="width: 310px"
    >
      <vs-select-item :key="index" :value="item.value" :text="item.text"
                      v-for="(item,index) in codeList"/>
    </vs-select>
    <v-btn v-on="on" icon :ripple="false" @click="clickSummaryDialog">
      <v-icon medium>mdi-message-text-outline</v-icon>
    </v-btn>
    <v-btn v-on="on" icon :ripple="false" @click="clickDoctorDialog">
      <v-icon medium>mdi-comment-account-outline</v-icon>
    </v-btn>

    <router-link to="/reportV2">
      <v-btn v-on="on" icon :ripple="false">
        <v-icon medium>mdi-file-chart</v-icon>
      </v-btn>
    </router-link>
    <div style="width: 50px"></div>
<!--
    <v-btn v-on="on" icon :ripple="false">
      <v-icon medium></v-icon>
    </v-btn>
-->


    <router-link to="/home">
      <v-btn v-on="on" icon :ripple="false">
        <v-icon medium>mdi-view-dashboard</v-icon>
      </v-btn>
    </router-link>

<!--
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" icon href="" :ripple="false">
            <v-icon medium>mdi-account-tie </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, index) in items"
              :key="index"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
-->


<!--    <v-menu class="toolbar-menu-item" offset-y origin="center center" :nudge-bottom="10" transition="scale-transition">
      <template v-slot:activator="{on3}">
        <v-btn icon large text v-on="on3" :ripple="false">
          <v-avatar size="42px">
            <img src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortFlat&accessoriesType=Sunglasses&hairColor=Black&facialHairType=Blank&clotheType=CollarSweater&clotheColor=Black&eyeType=Default&eyebrowType=Default&mouthType=Smile&skinColor=Light"/>
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-tile
            v-for="(item,index) in items"
            :key="index"
            :to="!item.href ? { name: item.name } : null"
            :href="item.href"
            ripple="ripple"
            :disabled="item.disabled"
            :target="item.target"
            @click="item.click">
          <v-list-tile-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-menu> -->
  </v-toolbar>
</template>
<script>

import { mapActions } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      rating: null,
      dialog: true,
      dialogSettings: false,
      switchEmailNotification: true,
      showPassword: null,
      showPasswordConfirm: null,
      userEmail: null,
      password: null,
      passwordConfirm: null,
      error: false,
      showResult: false,
      result: '',
      items: [
        {
          icon: 'account_circle',
          href: '#',
          title: 'Profile',
          click: () => {
          }
        },
        {
          icon: 'settings',
          href: '#',
          title: 'Settings',
          click: () => {
            const vm = this;

            vm.dialogSettings = true;
          }
        },
        {
          icon: 'exit_to_app',
          href: '#',
          title: 'Log Out',
          click: () => {
            const vm = this;
console.log("login");
            vm.$router.push({ name: 'Login' });
          }
        }
      ],
      notifications:
      [
        {
          title: 'New mail from Adam Joe',
          color: 'light-blue',
          icon: 'email',
          actionAt: '12 min ago',
          isActive: true,
          onClick: () => {
            const vm = this;

            vm.$router.push({ name: 'Mailbox' });
          }
        },
        {
          title: 'Scheculed meeting',
          color: 'red',
          icon: 'calendar_today',
          actionAt: '46 min ago',
          isActive: true,
          onClick: () => {
            const vm = this;

            vm.$router.push({ name: 'Calendar' });
          }
        },
        {
          title: 'New mail from Github',
          color: 'light-blue',
          icon: 'email',
          isActive: true,
          timeLabel: '2 hour ago',
          onClick: () => {
            const vm = this;

            vm.$router.push({ name: 'Mailbox' });
          }
        }
      ],
      languages: [
      ]
    }
  },


  computed: {
    userLevel () {
      return this.$store.state.user.level;
    },
    summaryDialog : {
      get(){
        return this.$store.state.dashBoard.summaryDialog;
      }
    },
    doctorDialog : {
      get(){
        return this.$store.state.dashBoard.doctorDialog;
      }
    },
    codeList() {
      return this.$store.state.codeList;
    },
    code : {
      get() {
        console.log("#user1 Main  Get Code:%s, Old Code: %s", this.$store.state.code, this.$store.state.oldCode)
        return this.$store.state.code
      },
      set(code) {
        console.log("SET code")
        this.$store.state.code = code;
        this.$store.dispatch('getSample', '').then( () => { console.log("[Done][getSample]")})
        /*
        this.updateChart();
        await this.getSample();
        this.$refs.doctorEditor.invoke('setHtml', this.$store.state.sample.comment);
        this.$refs.summaryEditor.invoke('setHtml', this.$store.state.sample.summary);
*/
        //}
      }
    }
  },
  mounted() {
    //todo 임시 해당 코드 리스트도 출력 되어야 함.
/*    axios.get('/v1/code').then( res => {

      if(res.data.code === 200) {
        console.log("/v1/code :: [%d]", res.data.code)
        let data = res.data.data;

        data.forEach((ele) => {
          this.codeList.push({text: `${ele.code}/${ele.flag}/${ele.name}`, value: ele.code })
        })
        this.$store.dispatch('getSample');

      }

    }).catch( e => {
      console.error(e);
    })*/
  },
  created () {

    if(this.$store.state.codeList.length <= 0){
      console.log("[Get Code List]")
      this.getCodeList();
    }
  },
  methods: {
    ...mapActions(["getCodeList"]),
    toggleNavigationBar() {
      const vm = this;

      vm.$emit('toggleNavigationBar');
    },
    clickSummaryDialog() {
      this.$store.commit('setSummaryDialog', !this.summaryDialog);
    },
    clickDoctorDialog() {
      this.$store.commit('setDoctorDialog', !this.doctorDialog);
    },
    report() {
      let age = this.$store.state.sample.age || 0 ;
      switch(true){
        case (age <= 1):
          this.$router.push('/report_baby');
          break;
        case (age <= 8):
          this.$router.push('/report');
          break;
        case (age >= 9 && age <= 19):
          this.$router.push('/report_teen');
          break;
        default :
          this.$router.push('/report_adult');
          break;
      }
    }


  },

};
</script>
<style>
  .toolbar-menu-item {
    padding-left: 5px;
  }

  .selected-language-flag {
    max-width: 45px;
  }

  .language-flag {
    max-width: 40px;
  }


  .languages-list-item {
    cursor: pointer;
    margin-top: -2px;
    margin-left: 1px;
  }

  .languages-list-item-title {
    font-size: 16px;
  }

  .languages-list-item-title:hover {
    color: #41B883;
    font-weight: bold;
  }
  .language-menu {
    border-radius: 25px;
    width: 235px;
    margin-right: 10px;
  }
  
  
</style>